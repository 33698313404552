var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" VTelehealth ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":require("@/assets/images/pages/register-v2.svg"),"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Đăng ký chủ nhà 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Bạn sẽ có 30 ngày dùng thử miễn phí! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Họ tên"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Họ tên "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false : null,"placeholder":"Nguyen Van A"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Số điện thoại"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số điện thoại "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false : null,"placeholder":"024.37368315"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Địa chỉ email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null,"placeholder":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Mật khẩu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mật khẩu","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Xác nhận mật khẩu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Xác nhận mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Xác nhận mật khẩu","vid":"password_confirm","rules":"required|password|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirm","type":_vm.passwordConfirmFieldType,"state":errors.length > 0 ? false : null,"name":"password_confirm","placeholder":"············"},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmToggleIcon},on:{"click":_vm.togglePasswordConfirmVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"agreement","rules":"agreement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Tôi đồng ý với "),_c('b-link',{attrs:{"href":"https://vntelehealth.com/chinh-sach-su-dung/","target":"_blank"}},[_vm._v(" Chính sách sử dụng ")]),_vm._v(" và "),_c('b-link',{attrs:{"href":"https://vntelehealth.com/dieu-khoan-bao-mat/","target":"_blank"}},[_vm._v(" Điều khoản bảo mật ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Đăng ký ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Bạn đã có tài khoản?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Đăng nhập")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }